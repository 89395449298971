import React, { useEffect } from "react";
import './Aboutus.css';
import Aos from "aos";
import 'aos/dist/aos.css'
import { Col, Container, Row } from "react-bootstrap";


export const AboutUs = () => {
    useEffect(() => {
        
        Aos.init({
            disable: "phone",
            duration: 1500,
        });
        Aos.refresh();
    }, []);
    return (
        <div>
          <section>
            <Container>
                <Row>
                    <Col lg={6}>
                    
                    </Col>
                </Row>
            </Container>
          </section>
           

        </div>
    )
}