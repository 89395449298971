import React from "react";
import { Button, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import './HeaderTop.css';
// import headerlogo from '../assets/Header-footer/smart-IG-logo.png';

export const HeaderTop = () => {
  return (
    <div>
      <Navbar expand="lg" className=' nav-bar-top '   style={{ paddingTop: '40px', position: 'relative', zIndex: '1' }} >
        <Container>
          <Navbar.Brand>
            <Link to='/'>
              <img className='w-100' src={require('../assets/Header-footer/smart-IG-logo.png')} alt='header-logo' loading="lazy" />
            </Link></Navbar.Brand>

          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="ms-auto  my-lg-0" navbarScroll>

              <NavDropdown title="Smart IG" id="basic-nav-dropdown" className="me-4">
                <Link className="dropdown-item" to="data-integration">Data Integration</Link>
                <Link className="dropdown-item" to="data-migration">Data Migration</Link>
                <Link className="dropdown-item" to="data-analytics">Data Analytics</Link>

              </NavDropdown>

              <Link className="nav-item nav-link top-links me-4" to="iMirAI">iMirAI</Link>
              {/* <Link className="nav-item nav-link top-links me-4" to="about-us">About Us</Link> */}

              <Link className="nav-item nav-link me-3" to="usecases">Use Cases</Link>

              <Link className="nav-item nav-link top-links me-4" to="pricing">Pricing</Link>

              <NavDropdown title="Media" id="basic-nav-dropdown" className="me-4" >
                {/* <Link className="dropdown-item" to="article">Articles</Link> */}
                <Link className="dropdown-item" to="linkedin">Linkedin</Link>
                <Link className="dropdown-item" to="youtube-videos">Youtube</Link>

              </NavDropdown>

              <Link className="nav-item nav-link top-links me-4" to="contact-us">Contact Us</Link>

              <Link to='https://calendly.com/customerdemos/smartig_mirai?month=2024-09'>
                <Button className="header-btn">Try</Button>
              </Link>

            </Nav>

          </Navbar.Collapse>
        </Container>
      </Navbar>

    </div>
  )
}