import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import SmartIGBroucher from '../assets/broucher/SmartIG-Brochure2.pdf';
import './smartig.css';

export const Smartig2 = () =>{

    const handleDownload = () => {
        

        const link = document.createElement('a');
        link.href = SmartIGBroucher;
        link.download = 'SmartIG-Brochure';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return(
        <>
        <section className="broch-smartig">
            <Container>
            <Row className="mt-5">
                        <Col>
                        
                            <Button type="submit" onClick={handleDownload}>
                                Download Brochure
                            </Button>
                        </Col>

                    </Row>
            </Container>
        </section>

       
        </>
    )
}