import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import './article.css';

export const Article = () => {
    const [showMore, setShowMore] = useState(false);

    const handleShowMore = () => {
        setShowMore(!showMore); // Toggle the visibility of the additional row
    };
    return (
        <div>
            <section className="blog-main-sec">
                <Container>
                    <Row className="text-center d-flex align-items-center justify-content-center media-hero">
                        <div className="media-main">
                            <p>BLOGS</p>
                        </div>
                        <p>
                            Unlock the business value of your data with an end-to-end tool that <br />
                            extracts, integrates, cleans and visualizes your data.
                        </p>

                    </Row>

                </Container>
            </section>
            <section style={{ height: '0px' }}>
                <Row style={{ height: '0px !important' }}>
                    <img src={require('../assets/Pricing/Img11.png')} className=" media-big-icon" alt="media-icon" />
                    <img src={require('../assets/Pricing/Img23.png')} className="medi-sma" alt="media" />
                </Row>
            </section>

            <section className="bg-light py-5">
                <Container>

                    <Row>
                        <Col lg={4} className="mt-4" >
                            <div className="blog-outer">
                                <div className="blog-inner-ig">

                                </div>
                                <div className="blog-co-car ">
                                    <h3>
                                        Media Articles
                                    </h3>
                                    <p>
                                        Unlock the business value of your data with
                                        an end-to-end tool that extracts, integrates,
                                        cleans and visualizes your data.
                                    </p>
                                </div>


                            </div>
                        </Col>
                        <Col lg={4} className="mt-4" >
                            <div className="blog-outer">
                                <div className="blog-inner-ig">

                                </div>
                                <div className="blog-co-car ">
                                    <h3>
                                        Media Articles
                                    </h3>
                                    <p>
                                        Unlock the business value of your data with
                                        an end-to-end tool that extracts, integrates,
                                        cleans and visualizes your data.
                                    </p>
                                </div>


                            </div>
                        </Col>
                        <Col lg={4} className="mt-4">
                            <div className="blog-outer">
                                <div className="blog-inner-ig">

                                </div>
                                <div className="blog-co-car ">
                                    <h3>
                                        Media Articles
                                    </h3>
                                    <p>
                                        Unlock the business value of your data with
                                        an end-to-end tool that extracts, integrates,
                                        cleans and visualizes your data.
                                    </p>
                                </div>


                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={4} className="mt-4" >
                            <div className="blog-outer">
                                <div className="blog-inner-ig">

                                </div>
                                <div className="blog-co-car ">
                                    <h3>
                                        Media Articles
                                    </h3>
                                    <p>
                                        Unlock the business value of your data with
                                        an end-to-end tool that extracts, integrates,
                                        cleans and visualizes your data.
                                    </p>
                                </div>


                            </div>
                        </Col>

                        <Col lg={4} className="mt-4" >
                            <div className="blog-outer">
                                <div className="blog-inner-ig">

                                </div>
                                <div className="blog-co-car ">
                                    <h3>
                                        Media Articles
                                    </h3>
                                    <p>
                                        Unlock the business value of your data with
                                        an end-to-end tool that extracts, integrates,
                                        cleans and visualizes your data.
                                    </p>
                                </div>


                            </div>
                        </Col>

                        <Col lg={4} className="mt-4" >
                            <div className="blog-outer">
                                <div className="blog-inner-ig">

                                </div>
                                <div className="blog-co-car ">
                                    <h3>
                                        Media Articles
                                    </h3>
                                    <p>
                                        Unlock the business value of your data with
                                        an end-to-end tool that extracts, integrates,
                                        cleans and visualizes your data.
                                    </p>
                                </div>


                            </div>
                        </Col>
                    </Row>

                   
                    <div className="text-center mt-4">

                        {showMore && ( 
                            <Row className="mt-4">
                                <Col lg={4}>
                                    <div className="blog-outer">
                                        <div className="blog-inner-ig"></div>
                                        <div className="blog-co-car ">
                                            <h3>Media Articles</h3>
                                            <p>
                                                Unlock the business value of your data with
                                                an end-to-end tool that extracts, integrates,
                                                cleans and visualizes your data.
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="blog-outer">
                                        <div className="blog-inner-ig"></div>
                                        <div className="blog-co-car ">
                                            <h3>Media Articles</h3>
                                            <p>
                                                Unlock the business value of your data with
                                                an end-to-end tool that extracts, integrates,
                                                cleans and visualizes your data.
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="blog-outer">
                                        <div className="blog-inner-ig"></div>
                                        <div className="blog-co-car ">
                                            <h3>Media Articles</h3>
                                            <p>
                                                Unlock the business value of your data with
                                                an end-to-end tool that extracts, integrates,
                                                cleans and visualizes your data.
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        )}
                       
                         <Button 
                            onClick={handleShowMore} 
                            className="show-more-btn"
                        >
                            <div className="btn-inner">
                            {showMore ? (
                               <KeyboardDoubleArrowUpIcon className="up-arrow"/>
                            ) : (
                              
                               <KeyboardDoubleArrowDownIcon className="down-arrow"/>
                            )}
                            </div>
                           
                        </Button>
                    </div>

                </Container>
            </section>


        </div>
    )
}