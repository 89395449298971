import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './datamigration.css';
import { Helmet } from "react-helmet";

export const Datamigration = () => {

    // const datamigrationdata = [
    //     {
    //         title: 'Application Migration',
    //         content: 'Consolidate applications and modernize solutions for better productivity and extended value for your IT infrastructure. Migrate all your applications and software from private to public cloud, or on-premise to cloud for centralized access, better management, and robust security',
    //     },
    //     {
    //         title: 'Data Center Migration',
    //         content: 'Data center relocation is mandatory for ever-growing businesses that want to anticipate technological advancement. Migrate your data center for your organizational expansion, regulatory requirements, and a company merger. Our data migration experts ensure smooth transitioning, minimum downtime, and data security throughout the transition.',
    //     },
    //     {
    //         title: 'Cloud Migration',
    //         content: 'Have seamless and secure data transfer from on-premise servers to the cloud or from one cloud to another cloud with data integrity and zero downtime. Optimize data processing and data storage of your organization through cloud platforms such as Amazon Web Services, Azure, and Google Cloud.'
    //     },
    //     {
    //         title: 'Platform Migrations',
    //         content: 'Transfer your data from one platform to another without data corruption or loss. From planning to execution, our data migration experts handle the entire process and ensure smooth data transition by mitigating risks.'
    //     },
    //     {
    //         title: 'Database Migration',
    //         content: 'Is it complex to upgrade or switch between various database technologies, diverse structures, and different versions?. No more worries. We handle your database migration by optimizing performance and safeguarding your data quality and integrity.'
    //     }

    // ]


    // const [openIndex, setOpenIndex] = useState(0);

    // const toggleAccordion = (index) => {
    //     setOpenIndex(openIndex === index ? null : index);
    // };

    // new carousel
    const [currentIndex, setCurrentIndex] = useState(0);

    const slides = [
        <div key={1}>
            <Row className="align-items-center justify-content-center py-5">
                <Col lg={6}>
                    <div className="carousel-img ">
                        <img src={require('../assets/data-migration/Data-Extraction.png')} className="w-100" alt="interactive-dashboard" />
                    </div>

                </Col>

                <Col lg={5} className='text-start'  >
                    <div className="curtain p-5 text-light">
                        <h3> Data Extraction</h3>
                        <p className=" text-start py-2" >
                            <b>Extract Data:</b> Pull data from the source systems using methods like
                            SQL queries, APIs, or file imports.
                        </p>
                        <p>
                            <b>Loading to Staging Area:</b> Load extracted data into a staging area for further
                            processing and validation.
                        </p>
                        {/* <div className="inner-cot-car">
                            <div className="d-flex align-items-center justify-content-center">
                                <KeyboardDoubleArrowRightIcon className="mr-5" />
                                <p>
                                    Intuitive UI
                                </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                <KeyboardDoubleArrowRightIcon className="mr-5" />
                                <p>
                                    Seamless navigation
                                </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                <KeyboardDoubleArrowRightIcon className="mr-5" />
                                <p>
                                    Easy to use drag and drop interface
                                </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                <KeyboardDoubleArrowRightIcon className="mr-5" />
                                <p>
                                    Export dashboards with ease
                                </p>
                            </div>

                        </div> */}

                    </div>
                </Col>
            </Row>
        </div>,

        <div key={2}>
            <Row className="align-items-center justify-content-center py-5">
                <Col lg={6}>
                    <div className="carousel-img">
                        <img src={require('../assets/data-migration/Data-Validation.png')} className="w-100" alt="access-various" />
                    </div>

                </Col>
                <Col lg={5} >
                    <div className="curtain p-5 text-start text-light">
                        <h3>Data Validation </h3>
                        <p className="py-2" >
                            <b> Perform Quality Checks: </b> Validate data for accuracy, completeness, and
                            consistency (e.g., type validation, null checks).
                        </p>
                        <p>
                            <b>Conduct Sample Testing:</b> Review a subset of data to ensure its quality and
                            correctness.processing and validation.
                        </p>
                        <p>
                            <b>Automate Validation: </b> Use automated tools for faster validation of larger datasets.
                        </p>
                        {/* <div className="inner-cot-car" >
                            <div className="d-flex align-items-center justify-content-center">
                                <KeyboardDoubleArrowRightIcon className="mr-5" />
                                <p>
                                    Dashboard Level Controls
                                </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                <KeyboardDoubleArrowRightIcon className="mr-5" />
                                <p>
                                    Card Level Controls
                                </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                <KeyboardDoubleArrowRightIcon className="mr-5" />

                                <p>
                                    Chart Level Controls
                                </p>
                            </div>

                        </div> */}
                    </div>
                </Col>
            </Row>


        </div>,

        <div key={3}>
            <Row className="align-items-center justify-content-center py-5">
                <Col lg={6}>

                    <div className="carousel-img" >
                        <img src={require('../assets/data-migration/data-transformation.png')} className="w-100" alt="uncover-insights" />
                    </div>

                </Col>
                <Col lg={5} >
                    <div className="curtain text-start p-5 text-light">
                        <h3>  Data Transformation</h3>
                        <p className=" py-2" style={{ fontWeight: '500' }}>
                            <b>Apply Mapping:</b> Pull data from the source systems using methods like SQL
                            queries, APIs, or file imports.
                        </p>
                        <p>
                            <b>Cleanse Data:</b> Load extracted data into a staging area for further processing
                            & validation.
                        </p>
                        <p>
                            <b>Enrich Data:</b> Enhance data by adding missing information or deriving new values.
                        </p>
                        {/* <div className="inner-cot-car">
                            <div className="d-flex align-items-center justify-content-center">
                                <KeyboardDoubleArrowRightIcon className="mr-5" />
                                <p>
                                    Unveil trends, patterns, correlations
                                </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                <KeyboardDoubleArrowRightIcon className="mr-5" />
                                <p>
                                    Empower smart decisions
                                </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                <KeyboardDoubleArrowRightIcon className="mr-5" />
                                <p>
                                    Gain actionable insights and recommendations
                                </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                <KeyboardDoubleArrowRightIcon className="mr-5" />
                                <p>
                                    Drive business growth
                                </p>
                            </div>
                        </div> */}
                    </div>
                </Col>
            </Row>
        </div>,

        <div key={4}>

            <Row className="align-items-center justify-content-center py-5">
                <Col lg={6}>

                    <div className="carousel-img" >
                        <img src={require('../assets/data-migration/data-consolidation.png')} className="w-100" alt="predictive-prescriptive" />

                    </div>

                </Col>
                <Col lg={5} >
                    <div className="curtain text-start p-5 text-light">
                        <h3>Data consolidation and merging</h3>
                        <p className=" py-2" style={{ fontWeight: '500' }} >
                            <b>Consolidate Sources:</b> If migrating from multiple systems, merge data into a
                            unified dataset.
                        </p>
                        <p>
                            <b>Load to Target System:</b> Transfer the validated and transformed data into the
                            production environment.
                        </p>
                        <p>
                            <b>Monitor the Loading Process:</b> Track the process for any errors or issues that may
                            arise.
                        </p>
                        <p>
                            <b>Resolve Conflicts:</b> Address discrepancies during merging by applying defined rules.
                        </p>
                        {/* <p>
                            <b>Integrate Data: </b> Ensure the merged data fits the target schema and
                            maintains integrity.
                        </p> */}
                        {/* <div className="inner-cot-car">
                            <div className="d-flex align-items-center justify-content-center">
                                <KeyboardDoubleArrowRightIcon className="mr-5" />
                                <p>
                                    Availability of different filters
                                </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                <KeyboardDoubleArrowRightIcon className="mr-5" />

                                <p>
                                    Editable Titles and Text Positions
                                </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                <KeyboardDoubleArrowRightIcon className="mr-5" />
                                <p>
                                    Trends creation
                                </p>
                            </div>
                        </div> */}
                    </div>
                </Col>
            </Row>

        </div>,

        <div key={5}>
            <Row className="align-items-center justify-content-center py-5">
                <Col lg={6}>
                    <div className="carousel-img" >
                        <img src={require('../assets/data-migration/Data-Loading-1.png')} className="w-100" alt="imirai" />

                    </div>

                </Col>
                <Col lg={5} >
                    <div className="curtain text-start p-5 text-light">
                        <h3> Data Loading</h3>
                        <p className=" py-2" style={{ fontWeight: '500' }}>
                            <b>Load to Target System:</b> Transfer the validated and transformed data into the
                            production environment.
                        </p>
                        <p>
                            <b>Monitor the Loading Process:</b> Track the process for any errors or issues that may
                            arise.
                        </p>
                        {/* <div className="inner-cot-car">
                            <div className="d-flex align-items-start justify-content-start">
                                <KeyboardDoubleArrowRightIcon className="mr-5" />
                                <p>
                                    High-level analytics reveals performance trends and key indicators.
                                </p>
                            </div>
                            <div className="d-flex align-items-start justify-content-start">
                                <KeyboardDoubleArrowRightIcon className="mr-5" />
                                <p>
                                    Granula-level analytics can spot anomalies, correlations, and outliers.
                                </p>
                            </div>
                           


                        </div> */}
                    </div>
                </Col>
            </Row>

        </div>,

        <div key={6}>
            <Row className="align-items-center justify-content-center py-5">
                <Col lg={6}>
                    <div className="carousel-img" >
                        <img src={require('../assets/data-migration/Reconciliation.png')} className="w-100" alt="imirai" />

                    </div>

                </Col>
                <Col lg={5} >
                    <div className="curtain text-start p-5 text-light">
                        <h3> Reconciliation</h3>
                        <p className=" py-2" style={{ fontWeight: '500' }}>
                            <b>Post-Migration Validation:</b> Perform comprehensive checks to verify data
                            completeness and accuracy in the target system.
                        </p>
                        <p>
                            <b>Create Audit Trails:</b> oDocument the migration process, including discrepancies
                            and resolutions.
                        </p>
                        <p>
                            <b>Stakeholder Review:</b> oEngage stakeholders to review the final data and ensure it
                            meets business requirements.
                        </p>
                        {/* <div className="inner-cot-car">
                        <div className="d-flex align-items-start justify-content-start">
                            <KeyboardDoubleArrowRightIcon className="mr-5" />
                            <p>
                                High-level analytics reveals performance trends and key indicators.
                            </p>
                        </div>
                        <div className="d-flex align-items-start justify-content-start">
                            <KeyboardDoubleArrowRightIcon className="mr-5" />
                            <p>
                                Granula-level analytics can spot anomalies, correlations, and outliers.
                            </p>
                        </div>
                       


                    </div> */}
                    </div>
                </Col>
            </Row>

        </div>,


    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const goToNextSlide = () => {
        const nextIndex = (currentIndex + 1) % slides.length;
        setCurrentIndex(nextIndex);
    };

    const goToPreviousSlide = () => {
        const nextIndex = currentIndex === 0 ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(nextIndex);
    };


    useEffect(() => {
        const intervalId = setInterval(() => {
            goToNextSlide();
        }, 3000);

        // Cleanup the interval when the component unmounts or currentIndex changes
        return () => clearInterval(intervalId);
    }, [currentIndex, goToNextSlide, slides.length]);



    return (
        <div>

            <Helmet>
                <title> Data Migration Consulting Services & Solutions - Smart IG </title>

                <meta name="description" content="Smart Integraphics offers expert Data Migration, transitioning data from various sources to cloud solutions for improved performance and efficiency." />

                <meta name="author" content="Smart InteGraphics" />

                <meta name="robots" content="index, follow" />

                {/* Og Tags */}

                <meta property="og:title" content="Data Migration Consulting Services & Solutions - Smart IG." />

                <meta property="og:description" content="Smart Integraphics offers expert Data Migration, transitioning data from various sources to cloud solutions for improved performance and efficiency." />

                {/* <meta property="og:image" content={homebanner} /> */}

                <meta property="og:url" content="https://smartintegraphics.com/data-migration" />

                {/* <meta name="twitter:card" content="summary_large_image" /> */}

                <meta name="twitter:title" content="Data Migration Consulting Services & Solutions - Smart IG." />

                <meta name="twitter:description" content="Smart Integraphics offers expert Data Migration, transitioning data from various sources to cloud solutions for improved performance and efficiency." />

                {/* <meta name="twitter:image" content={homebanner} /> */}
            </Helmet>


            <section className="data-migration-main">
                <Container>
                    <Row style={{ height: '0px' }}>
                        <img src={require('../assets/Pricing/Img8-3d.png')} className="data-mig-ico-2" alt="data-migration-icon-2" />
                    </Row>
                    <Row className="text-center d-flex align-items-center justify-content-center media-hero">
                        <div className="data-migration-hero-sec">
                            <p>DATA MIGRATION</p>
                        </div>
                        <p>
                            In today's era of abundant data, businesses gather information from <br />diverse sources, including sales, operations, and customer interactions.
                        </p>
                    </Row>
                    <Row style={{ height: '0px' }}>
                        <img src={require('../assets/Pricing/Img8-3d.png')} className="data-mig-ico-big-1" alt="data-migration-icon-1" />
                    </Row>

                </Container>
            </section>

            <section className="bg-light">
                <Container>
                    <Row className="py-5 align-items-center justify-content-center">
                        <Col lg={4}>
                            <img src={require('../assets/data-migration/data-migration-1.png')} className="w-100" alt="data-migration" />
                        </Col>
                        <Col lg={7} className="px-5">
                            <p className="data-migration-process-involves">
                                Data migration is a process that involves moving data from one place to another. It can also be
                                done in different formats or applications. This is usually the result of moving data to a new
                                location or system. Business drivers are usually migrations or consolidations of legacy systems,
                                which will be replaced or enhanced by new applications sharing the same dataset. Data
                                migrations often begin when firms migrate from on-premises applications and infrastructure to
                                cloud storage to optimize their business
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* <section className="py-5">
                    <Container>
                        <Carousel ref={carouselRef} indicators={false} interval={null}>
                            <Carousel.Item>
                                <div className="" >
                                    <Row className="align-items-center p-5">
                                        <Col lg={4}>
                                            <div className="data-migra-car-head">
                                                <h4>Agile data migration</h4>
                                            </div>
                                            <div className="data-mig-car-bod">
                                                <p>
                                                    The entire process will be divided into
                                                    sub-migrations with different timelines,goals,
                                                    scope, and quality checks.
                                                </p>
                                            </div>
                                        </Col>

                                        <Col lg={4}>
                                            <div className="data-migra-car-head">
                                                <h4>Cross-functional allied teams</h4>
                                            </div>
                                            <div className="data-mig-car-bod">
                                                <p>
                                                    Our team of professionals go hand-in-hand
                                                    to contribute to the common goal. Businesses
                                                    can benefit from diverse perspectives, expertise,
                                                    improved communication & collaboration, and
                                                    data-driven decision-making.
                                                </p>
                                            </div>
                                        </Col>

                                        <Col lg={4}>
                                            <div className="data-migra-car-head">
                                                <h4>Client-focused</h4>
                                            </div>
                                            <div className="data-mig-car-bod">
                                                <p>
                                                    We prioritize customer requirements and their
                                                    satisfaction above all. Thus, we create
                                                    the best
                                                    experience for them and build trust in our brand.
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Carousel.Item>

                            <Carousel.Item>
                                <div className="" >
                                    <Row className="align-items-center p-5">
                                        <Col lg={5}>
                                            <div className="data-migra-car-head">
                                                <h4>Proven track record</h4>
                                            </div>
                                            <div className="data-mig-car-bod">
                                                <p>
                                                    We have a track record of project delivery for multiple industries and a stellar reputation
                                                    for providing seamless data migration solutions for optimal performance, catering to
                                                    various business needs with precision and efficiency.
                                                </p>
                                            </div>
                                        </Col>

                                        <Col lg={5}>
                                            <div className="data-migra-car-head">
                                                <h4>Quality assurance</h4>
                                            </div>
                                            <div className="data-mig-car-bod">
                                                <p>
                                                    We ensure the quality of your migrated data through data quality assessment in
                                                    which test scripts and test cases are created to ensure data accuracy, consistency,
                                                    relevance, timeliness, completeness, and integrity during and after migration.
                                                </p>
                                            </div>
                                        </Col>

                                    
                                    </Row>
                                </div>
                            </Carousel.Item>

                        
                        </Carousel>
                        <div className="data-migra-prev-next mt-2">
                            <Button onClick={handlePrev} className="prew-main-btn">
                                <div className="prew-btn-migra">
                                    <ArrowBackIcon />
                                    <p>Prew</p>
                                </div>
                            </Button>
                            <Button onClick={handleNext} className="prew-main-btn">
                                <div className="next-btn-migra">
                                    <p>Next</p>
                                    <ArrowForwardIcon />
                                </div>
                            </Button>
                        </div>
                    </Container>
                </section> */}

            <section className="py-5 bg-light">
                <Container className="interactive-carousel">
                    <Row style={{ position: 'relative', height: '0px' }}>
                        <img src={require('../assets/Pricing/Img8-3d.png')} className="smal-tri-car" alt="tri-small-car" />
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <div className="carousel">
                                <div className="slide">
                                    {slides[currentIndex]}
                                </div>

                            </div>
                            <div className="car-btu">
                                <button onClick={goToPreviousSlide} className="arro-pre-bt" >< ArrowBackIcon /></button>
                                <button onClick={goToNextSlide} className="arro-pre-bt"><ArrowForwardIcon /></button>
                            </div>

                        </Col>
                    </Row>

                    <Row style={{ position: 'relative', height: '0px' }}>
                        <img src={require('../assets/Pricing/Img8-3d.png')} className="big-cube-1" alt="tri-small-car" />
                    </Row>
                </Container>
            </section>


            {/* <section className="py-5">
                <Container>
                    <Row className="text-center">
                        <h2 className="migration-service-head">
                            What Migration Services We Offer
                        </h2>
                    </Row>
                    <Row className="py-5 align-items-center justify-content-center">

                        <Col lg={6} className="px-5">
                            <div className="data-migra-accordion">
                                {datamigrationdata.map((item, index) => (
                                    <div key={index} className="data-migration-item">
                                        <div className="data-migra-head-acc" onClick={() => toggleAccordion(index)}>
                                            <button className="data-migration-acc-btn">
                                                <div className="d-flex align-items-center justify-content-between px-5">
                                                    <h4 className="data-mig-ac-tit">
                                                        {item.title}
                                                    </h4>

                                                    <KeyboardArrowDownIcon
                                                        className={`arrow-icon ${openIndex === index ? 'rotated' : ''}`}
                                                    />
                                                </div>


                                            </button>
                                            {openIndex === index && (
                                                <div className="data-migration-conte" >
                                                    <p className="pb-4 data-migration-conte">
                                                        {item.content}
                                                    </p>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                ))}
                            </div>
                        </Col>

                        <Col lg={6}>
                            <img src={require('../assets/data-migration/Migration-Services.png')} className="w-100" alt="data-migration-services" />
                        </Col>

                    </Row>
                </Container>
            </section> */}


        </div>
    )
}