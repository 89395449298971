import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import './Footer.css';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailIcon from '@mui/icons-material/Mail';
import CallIcon from '@mui/icons-material/Call';
import { Link } from "react-router-dom";
import XIcon from '@mui/icons-material/X';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

export const Footer = () => {


    return (
        <div>

            <section className="footer-main">
                <Container >
                    <Row className=" text-light  footer-inner " >
                        <Col lg={5} sm={7} xs={12}>
                            <img src={require('../assets/Header-footer/Smart-IG-White-LOGO.png')} style={{ width: '100%' }} alt="logo" />

                            <div className="power-data-footer mt-3">
                                <h2 style={{ fontSize: '1.5rem' }}>Powered By Data Science.</h2>
                                <p >
                                    Argumented By AI. Built For People.
                                </p>

                            </div>

                            <div className="">
                                <div className="footer-detail mt-3">
                                    <LocationOnIcon className="locat-ico" />
                                    <p>
                                        Dubai Silicon Oasis, Mirpur 2 DDP,Building A2, United Arab Emirates
                                    </p>
                                </div>

                                <div className="footer-detail mt-2 ">
                                    <MailIcon className="mail-ico" />
                                    <h6>
                                        info@integraldataanalytics.com
                                    </h6>
                                </div>

                                <div className="footer-detail mt-2">
                                    <CallIcon className="call-ico" />
                                    <h6>+971 568421293</h6>
                                </div>
                            </div>

                        </Col>
                        <Col lg={1} sm={1} xs={1}></Col>
                        <Col lg={2} sm={4} xs={4} className="footer-conte ">
                            <p>
                                <Link to='data-integration' className="link-contact-re">
                                    Data Integration
                                </Link>
                            </p>
                            <p>
                                <Link to='data-migration' className="link-contact-re">
                                    Data Migration
                                </Link>
                            </p>
                            <p>
                                <Link to='data-analytics' className="link-contact-re">
                                    Data Analytics
                                </Link>
                            </p>
                            <p>
                                <Link to='iMirAI' className="link-contact-re">
                                    iMirAI
                                </Link>
                            </p>


                        </Col>
                        <Col lg={1} sm={1} xs={1} className="second-footer-empt-co"></Col>
                        <Col lg={2} sm={4} xs={4} className="footer-conte ">
                            <p>
                                <Link to='usecases' className="link-contact-re">
                                    Use Cases
                                </Link>
                            </p>
                            <p>
                                <Link to='pricing' className="link-contact-re">
                                    Pricing
                                </Link>
                            </p>



                            <p>
                                <Link to='contact-us' className="link-contact-re">
                                    Contact Us
                                </Link>
                            </p>

                        </Col>
                    </Row>

                    <div className="d-flex gap-3 text-center align-items-center justify-content-center pb-4 mt-2 socio-ico-foo" >

                        <Link to='https://www.linkedin.com/company/integral-analytics-solutions/posts/?feedView=all'>
                            <div className="form-soci-icon-footer">
                                <LinkedInIcon className="facebook-footer" />
                            </div>
                        </Link>

                        <Link to='https://www.youtube.com/@Smart_IG'>
                            <div className="form-soci-icon-footer">
                                <YouTubeIcon className="facebook-footer" />
                            </div>
                        </Link>

                        <Link to='https://www.facebook.com/IntegralAnalyticsSolutions/'>
                            <div className="form-soci-icon-footer">
                                <FacebookOutlinedIcon className="facebook-footer" />
                            </div>
                        </Link>

                        <Link to='https://www.instagram.com/integralanalytics/'>
                            <div className="form-soci-icon-footer">
                                <InstagramIcon className="facebook-footer" />
                            </div>
                        </Link>

                        <Link to='https://x.com/i/flow/login?redirect_after_login=%2FIntegralSmartIG'>
                            <div className="form-soci-icon-footer">
                                <XIcon className="facebook-footer" />
                            </div>
                        </Link>

                    </div>

                </Container>
            </section>
        </div>
    )
}