import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CircleIcon from '@mui/icons-material/Circle';
import Aos from "aos";
import 'aos/dist/aos.css'
import './usecase.css';
import salesAnalysis from '../assets/use cases/Sales-Analysis.png';
import cutomerInsights from '../assets/use cases/Customer-Insights.png';
import MarketingOptimization from '../assets/use cases/Marketing-Optimization.png';
import operationalEffience from '../assets/use cases/Operational-Efficiency.png';
import Financialanalysis from '../assets/use cases/Financial-Analysis.png';
import hranalytics from '../assets/use cases/HR-Analytics.png';
import supplychain from '../assets/use cases/Supply-Chain-Optimization.png';
import Predictivemainataince from '../assets/use cases/Predictive-Maintenance.png';
import customerservice from '../assets/use cases/Customer-Service-Analysis.png';
import strategicplanning from '../assets/use cases/Strategic-Planning.png';
import riskmanagement from '../assets/use cases/Risk-Management.png';
import compliancemonitoring from '../assets/use cases/Compliance-Monitoring.png';
import manfacturing from '../assets/use cases/Manufacturing-Optimization.png';
import retailanalytics from '../assets/use cases/Retail-Analytics.png';
import healthcare from '../assets/use cases/Healthcare-Analytics.png';
import Educationa from '../assets/use cases/Education-Analytics.png';
import realesatate from '../assets/use cases/Real-Estate-Analytics.png';
import energy from '../assets/use cases/Energy-and-Utilities-Analytics.png';
import ico1 from '../assets/Pricing/Img6.png';
import { Helmet } from "react-helmet";


export const Usecase = () => {
    useEffect(() => {

        Aos.init({
            disable: "phone",
            duration: 1500,
        });
        Aos.refresh();
    }, []);
    return (
        <div>

            <Helmet>
                <title>Data Analytics Use Cases Across Industries  - Smart IG </title>

                <meta name="description" content="Smart Integraphics offers data analytics solutions for every industry, extracting, integrating, cleansing, and visualizing data to unlock business value." />

                <meta name="author" content="Smart InteGraphics" />

                <meta name="robots" content="index, follow" />

                {/* Og Tags */}

                <meta property="og:title" content="Data Analytics Use Cases Across Industries  - Smart IG" />

                <meta property="og:description" content="Smart Integraphics offers data analytics solutions for every industry, extracting, integrating, cleansing, and visualizing data to unlock business value." />

                {/* <meta property="og:image" content={homebanner} /> */}

                <meta property="og:url" content="https://smartintegraphics.com/usecases" />

                {/* <meta name="twitter:card" content="summary_large_image" /> */}

                <meta name="twitter:title" content="Data Analytics Use Cases Across Industries  - Smart IG" />

                <meta name="twitter:description" content="Smart Integraphics offers data analytics solutions for every industry, extracting, integrating, cleansing, and visualizing data to unlock business value." />

                {/* <meta name="twitter:image" content={homebanner} /> */}
            </Helmet>



            <section className="mt-5 py-5">
                <Container>
                    <Row className="about-main-icon-top">
                        <img src={ico1} className="about-icon-1" alt="icon-1" loading="lazy" />
                    </Row>
                    <Row className="about-main-ico-2">
                        <img src={ico1} className="about-icon-2" alt="icon-2" loading="lazy" />
                    </Row>
                    <Row className="text-center about-main-1 ">

                        <Row className="text-center align-items-center justify-content-center">

                            <div className="about-2">
                                <p>
                                    USE CASES
                                </p>
                            </div>
                        </Row>

                        <p>
                            Unlock the business value of your data with an end-to-end tool  that extracts,<br /> integrates , clean and visualizes your data.
                        </p>
                    </Row>
                </Container>
            </section>

            <section>
                <Container>
                    <Row className="mb-4 align-items-center justify-content-center" data-aos="fade-up"  >
                        <Col lg={5}>
                            <div className="about-card-1 p-4">
                                <div className="">
                                    <h4 className="fw-bold">
                                        Sales Analysis:
                                    </h4>
                                    <div className="" style={{ fontWeight: '500', listStyle: 'none' }}>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Track sales trends and performance
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Analyze sales by region, product, and time period
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Identify top-performing sales teams and products
                                        </li>
                                    </div>
                                </div>

                                <img src={salesAnalysis} className="head-sec-use-case" alt="sales-analysis" loading="lazy" />

                            </div>
                        </Col>
                        <Col lg={5}>
                            <div className="about-card-1 p-4">
                                <div className="">
                                    <h4 className="fw-bold">
                                        Customer Insights:
                                    </h4>
                                    <div className="" style={{ fontWeight: '500', listStyle: 'none' }}>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Analyze customer demographics and behaviour
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Identify customer segments and preferences
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Track customer retention and churn rates
                                        </li>
                                    </div>
                                </div>
                                <img src={cutomerInsights} className="head-sec-use-case" alt="sales-analysis" loading="lazy" />

                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-4 align-items-center justify-content-center" data-aos="fade-up" >
                        <Col lg={5}>
                            <div className="about-card-2 p-4">
                                <div className="">
                                    <h4 className="fw-bold">
                                        Marketing Optimization:
                                    </h4>
                                    <div className="" style={{ fontWeight: '500', listStyle: 'none' }}>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Measure campaign effectiveness and ROI
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Analyze customer engagement and response rates
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Identify optimal marketing channels and strategies
                                        </li>
                                    </div>
                                </div>

                                <img src={MarketingOptimization} className="head-sec-use-case" alt="sales-analysis" loading="lazy" />
                            </div>
                        </Col>
                        <Col lg={5}>
                            <div className="about-card-2 p-4">
                                <div className="">
                                    <h4 className="fw-bold">
                                        Operational Efficiency:
                                    </h4>
                                    <div className="" style={{ fontWeight: '500', listStyle: 'none' }}>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Monitor key performance indicators (KPIs) and metrics
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Identify areas for process improvement and cost reduction
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Track inventory levels and supply chain performance
                                        </li>
                                    </div>
                                </div>
                                <img src={operationalEffience} className="head-sec-use-case" alt="sales-analysis" loading="lazy" />
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-4 align-items-center justify-content-center" data-aos="fade-up"  >
                        <Col lg={5}>
                            <div className="about-card-1 p-4">
                                <div className="">
                                    <h4 className="fw-bold">
                                        Financial Analysis:
                                    </h4>
                                    <div className="" style={{ fontWeight: '500', listStyle: 'none' }}>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Analyze revenue and expense trends
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Track budget vs. actual performance
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Identify areas for cost savings and optimization
                                        </li>
                                    </div>
                                </div>
                                <img src={Financialanalysis} className="head-sec-use-case" alt="sales-analysis" loading="lazy" />
                            </div>
                        </Col>
                        <Col lg={5}>
                            <div className="about-card-1 p-4">
                                <div className="">
                                    <h4 className="fw-bold">
                                        HR Analytics:
                                    </h4>
                                    <div className="" style={{ fontWeight: '500', listStyle: 'none' }}>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Analyze employee turnover and retention rates
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Track training and development program effectiveness
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Identify talent gaps and succession planning needs
                                        </li>
                                    </div>
                                </div>
                                <img src={hranalytics} className="head-sec-use-case" alt="sales-analysis" loading="lazy" />
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-4 align-items-center justify-content-center" data-aos="fade-up" >
                        <Col lg={5}>
                            <div className="about-card-2 p-4">
                                <div className="">
                                    <h4 className="fw-bold">
                                        Supply Chain Optimization:
                                    </h4>
                                    <div className="" style={{ fontWeight: '500', listStyle: 'none' }}>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Analyze supplier performance and quality metrics
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Track inventory levels and shipping times
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Identify opportunities for cost reduction and process improvement
                                        </li>
                                    </div>
                                </div>
                                <img src={supplychain} className="head-sec-use-case" alt="sales-analysis" loading="lazy" />
                            </div>
                        </Col>
                        <Col lg={5}>
                            <div className="about-card-2 p-4">
                                <div className="">
                                    <h4 className="fw-bold">
                                        Predictive Maintenance:
                                    </h4>
                                    <div className="" style={{ fontWeight: '500', listStyle: 'none' }}>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Analyze equipment performance and failure rates
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Predict maintenance needs and schedule downtime
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Reduce maintenance costs and improve uptime
                                        </li>
                                    </div>
                                </div>
                                <img src={Predictivemainataince} className="head-sec-use-case" alt="sales-analysis" loading="lazy" />
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-4 align-items-center justify-content-center" data-aos="fade-up" >
                        <Col lg={5}>
                            <div className="about-card-1 p-4">
                                <div className="">
                                    <h4 className="fw-bold">
                                        Customer Service Analysis:
                                    </h4>
                                    <div className="" style={{ fontWeight: '500', listStyle: 'none' }}>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Track customer service metrics (e.g., response time, resolution rate)
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Analyze customer feedback and sentiment
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Identify areas for improvement and training needs
                                        </li>
                                    </div>
                                </div>
                                <img src={customerservice} className="head-sec-use-case" alt="sales-analysis" loading="lazy" />
                            </div>

                        </Col>
                        <Col lg={5}>
                            <div className="about-card-1 p-4">
                                <div className="">
                                    <h4 className="fw-bold">
                                        Strategic Planning:
                                    </h4>
                                    <div className="" style={{ fontWeight: '500', listStyle: 'none' }}>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Analyze market trends and competitor activity
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Identify opportunities for growth and expansion
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Develop data-driven strategic plans and initiatives
                                        </li>
                                    </div>
                                </div>
                                <img src={strategicplanning} className="head-sec-use-case" alt="sales-analysis" loading="lazy" />
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-4 align-items-center justify-content-center" data-aos="fade-up">
                        <Col lg={5}>
                            <div className="about-card-2 p-4">
                                <div className="">
                                    <h4 className="fw-bold">
                                        Risk Management:
                                    </h4>
                                    <div className="" style={{ fontWeight: '500', listStyle: 'none' }}>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Identify and assess potential risks and threats
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Analyze risk mitigation strategies and effectiveness
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Develop data-driven risk management plans
                                        </li>
                                    </div>
                                </div>
                                <img src={riskmanagement} className="head-sec-use-case" alt="sales-analysis" loading="lazy" />
                            </div>
                        </Col>
                        <Col lg={5}>
                            <div className="about-card-2 p-4">
                                <div className="">
                                    <h4 className="fw-bold">
                                        Compliance Monitoring:
                                    </h4>
                                    <div className="" style={{ fontWeight: '500', listStyle: 'none' }}>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Track regulatory compliance metrics and requirements
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Analyze audit results and identify areas for improvement
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Develop compliance dashboards and reports
                                        </li>
                                    </div>
                                </div>
                                <img src={compliancemonitoring} className="head-sec-use-case" alt="sales-analysis" loading="lazy" />
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-4 align-items-center justify-content-center" data-aos="fade-up" >
                        <Col lg={5}>
                            <div className="about-card-1 p-4">
                                <div className="">
                                    <h4 className="fw-bold">
                                        Manufacturing Optimization:
                                    </h4>
                                    <div className="" style={{ fontWeight: '500', listStyle: 'none' }}>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Analyze production metrics (e.g., yield, throughput)
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Track quality control and defect rates
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Identify opportunities for process improvement and  cost reduction
                                        </li>
                                    </div>
                                </div>
                                <img src={manfacturing} className="head-sec-use-case" alt="sales-analysis" loading="lazy" />
                            </div>
                        </Col>
                        <Col lg={5}>
                            <div className="about-card-1 p-4">
                                <div className="">
                                    <h4 className="fw-bold">
                                        Retail Analytics:
                                    </h4>
                                    <div className="" style={{ fontWeight: '500', listStyle: 'none' }}>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Analyze sales and customer traffic patterns
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Track inventory levels and optimize stock replenishment
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Identify opportunities for store layout & display optimization
                                        </li>
                                    </div>
                                </div>
                                <img src={retailanalytics} className="head-sec-use-case" alt="sales-analysis" loading="lazy" />
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-4 align-items-center justify-content-center" data-aos="fade-up">
                        <Col lg={5}>
                            <div className="about-card-2 p-4">
                                <div className="">
                                    <h4 className="fw-bold">
                                        Healthcare Analytics:
                                    </h4>
                                    <div className="" style={{ fontWeight: '500', listStyle: 'none' }}>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Analyze patient outcomes and treatment effectiveness
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Track healthcare resource utilization and costs
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Identify opportunities for quality improvement and cost reduction
                                        </li>
                                    </div>
                                </div>
                                <img src={healthcare} className="head-sec-use-case" alt="sales-analysis" loading="lazy" />
                            </div>
                        </Col>
                        <Col lg={5}>
                            <div className="about-card-2 p-4">
                                <div className="">
                                    <h4 className="fw-bold">
                                        Education Analytics:
                                    </h4>
                                    <div className="" style={{ fontWeight: '500', listStyle: 'none' }}>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Analyze student performance and learning outcomes
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Track teacher effectiveness & professional development needs
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Identify opportunities for curriculum improvement and optimization
                                        </li>
                                    </div>
                                </div>
                                <img src={Educationa} className="head-sec-use-case" alt="sales-analysis" loading="lazy" />
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-4 align-items-center justify-content-center" data-aos="fade-up" >
                        <Col lg={5}>
                            <div className="about-card-1 p-4">
                                <div className="">
                                    <h4 className="fw-bold">
                                        Real Estate Analytics:
                                    </h4>
                                    <div className="" style={{ fontWeight: '500', listStyle: 'none' }}>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Analyze property values and market trends
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Track rental income and occupancy rates
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Identify opportunities for investment and development
                                        </li>
                                    </div>
                                </div>
                                <img src={realesatate} className="head-sec-use-case" alt="sales-analysis" loading="lazy" />
                            </div>
                        </Col>
                        <Col lg={5}>
                            <div className="about-card-1 p-4">
                                <div className="">
                                    <h4 className="fw-bold">
                                        Energy and Utilities Analytics:
                                    </h4>
                                    <div className="" style={{ fontWeight: '500', listStyle: 'none' }}>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Analyze energy consumption and usage patterns
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Track grid performance and outage metrics
                                        </li>
                                        <li className="list-in-use-case">
                                            <CircleIcon className="dot-ico-use" />
                                            Identify opportunities for energy efficiency and cost Reduction solutions.
                                        </li>
                                    </div>
                                </div>
                                <img src={energy} className="head-sec-use-case" alt="sales-analysis" loading="lazy" />
                            </div>
                        </Col>
                    </Row>

                    {/* <Row className="big-ico">
                    <img src={require('../assets/Pricing/Img6.png')} className="w-100" alt="icon-3"/>
                    </Row> */}

                </Container>
            </section>
        </div>
    )
}