// import logo from './logo.svg';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import { HeaderTop } from './components/HeaderTopComponent/HeaderTop';
import { Home } from './components/HomeComponent/Home';
import { Footer } from './components/Footer Component/Footer';
import { AboutUs } from './components/AboutUs Component/AboutUs';
import { Pricing } from './components/Pricing Component/Pricing';
import { Test } from './components/test Component/Test';
import { Usecase } from './components/UseCases Component/Usecases';
import { Article } from './components/media component/Article';
import { Linkedinposts } from './components/media component/Linkedinposts';
import { Youtube } from './components/media component/Youtube';
import { ContactUs } from './components/ContactUs Component/ContactUs';
import { Dataintegration } from './components/Smartig component/Dataintegration';
import { Datamigration } from './components/Smartig component/Datamigration';
import { Dataanlytics } from './components/Smartig component/Dataanalytics';
import { useEffect } from 'react';
import { ImirAI } from './components/ImirAIComponent/ImirAI';
import { Smartig } from './components/Broucher Components/SmartIg';
import { Smartig2 } from './components/Broucher Components/Smartig2';


function ScrollToTopOnNavigate() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  return (
    <div className='app'>
      <header>
        <HeaderTop />
      </header>

      <ScrollToTopOnNavigate/>
      <main>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='data-integration' element={<Dataintegration/>}/>
          <Route path='data-migration' element={<Datamigration/>}/>
          <Route path = 'data-analytics' element={<Dataanlytics/>}/>
          <Route path='about-us' element={<AboutUs/>}/>
          <Route path='pricing' element = {<Pricing/>}/>
          <Route path='usecases' element={<Usecase/>}/>
          <Route path='article' element={<Article/>}/>
          <Route path='linkedin' element={<Linkedinposts/>}/>
          <Route path='youtube-videos' element={<Youtube/>}/>
          <Route path='contact-us' element={<ContactUs/>}/>
          <Route path='test' element = {<Test/>}/>
          <Route path='iMirAI' element={<ImirAI/>}/>
          <Route path='SmartIG-Broucher' element={<Smartig/>}/>
          <Route path='SmartIG-Broucher-2' element={<Smartig2/>}/>
        </Routes>
      </main>
      
      <footer>
        <Footer/>
      </footer>
    </div>
  );
}

export default App;
