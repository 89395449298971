import React, { useState } from "react";
import { Button, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import './Pricing.css';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

export const Pricing = () => {
    const [key, setKey] = useState('data-integration');
    return (
        <div>

            <Helmet>
                <title>Data Integration & Analytics: Basic to Enterprise Solutions </title>

                <meta name="description" content="Smart Integraphics offers scalable, cost-effective data analytics, migration, and integration solutions with flexible pricing tiers for any business need." />

                <meta name="author" content="Smart InteGraphics" />

                <meta name="robots" content="index, follow" />

                {/* Og Tags */}

                <meta property="og:title" content="Data Integration & Analytics: Basic to Enterprise Solutions" />

                <meta property="og:description" content="Smart Integraphics offers scalable, cost-effective data analytics, migration, and integration solutions with flexible pricing tiers for any business need." />

                {/* <meta property="og:image" content={homebanner} /> */}

                <meta property="og:url" content="https://smartintegraphics.com/pricing" />

                {/* <meta name="twitter:card" content="summary_large_image" /> */}

                <meta name="twitter:title" content="Data Integration & Analytics: Basic to Enterprise Solutions" />

                <meta name="twitter:description" content="Smart Integraphics offers scalable, cost-effective data analytics, migration, and integration solutions with flexible pricing tiers for any business need." />

                {/* <meta name="twitter:image" content={homebanner} /> */}
            </Helmet>

            <section className="mt-5 py-5">
                <Container>
                    <Row className="text-center pricing-main">
                        <Row className="text-center align-items-center justify-content-center">

                            <div className="about-2">
                                <p>
                                    Price
                                </p>
                            </div>


                        </Row>
                        <h2 className="py-3 ">
                            Investment In Your  Future
                        </h2>
                        <p>
                            Unlock the business value of your data with an end-to-end tool  that extracts,<br /> integrates , clean and visualizes your data.
                        </p>
                    </Row>

                    <Row className="empty-ic-1">
                        <img src={require('../assets/Pricing/Img17.png')} className="price-icon-1" alt="icon-1" />
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                                className="price-data-head" style={{ marginBottom: '3rem' }}>
                                <Tab eventKey="data-integration" title="Data Integration">

                                    <Row>

                                        <Col lg={4} className="align-items-center justify-content-center mt-4 ">
                                            <div className="price-card-outer">
                                                <div className="price-header">
                                                    <h2>BASIC</h2>
                                                </div>
                                                <div className="Price-value">
                                                    <h4 className="mt-4">
                                                        $49.99/$65.99
                                                    </h4>
                                                    <p>
                                                        Per User<br />(Year/Month)
                                                    </p>

                                                    <p className="pice-user">
                                                        1 User
                                                    </p>

                                                </div>

                                                <div className="price-data-1">
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data-main">
                                                                <p>
                                                                    Files
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    XLS
                                                                </p>
                                                            </div>

                                                            <p>
                                                                <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    XLSX
                                                                </p>
                                                            </div>

                                                            <p>
                                                           <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    CSV
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    JSON
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data-main">
                                                                <p>
                                                                    DataBase
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Oracle
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    MS SQL
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    MY SQL
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    IBM DB2
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Maria Database
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Ms Access
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    SQL Lite
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    SQL Server
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Postgre SQL
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Mongo DB
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    IBM netezza
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Teradata
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Azure SQL
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data-main">
                                                                <p>
                                                                    ERP
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    SAP ECC
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    SAP HANA
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Oracle ERP
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    MS Dynamics
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data-main">
                                                                <p>
                                                                    Online Services
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    X
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Youtube
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Facebook
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    LinkedIn
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                    </div>


                                                </div>

                                                <div className="d-flex align-items-center justify-content-center">
                                                    <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Basic" target="blank">
                                                        <Button className="price-buy-now" >
                                                            Buy Now
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col lg={4} className="align-items-center justify-content-center mt-4 ">
                                            <div className="price-card-outer">
                                                <div className="price-header">
                                                    <h2>PROFESSIONAL</h2>
                                                </div>
                                                <div className="Price-value">
                                                    <h4 className="mt-4">
                                                        $79.99/$99.99
                                                    </h4>
                                                    <p>
                                                        Per User<br />(Year/Month)
                                                    </p>
                                                    <p className="pice-user">
                                                        3 User
                                                    </p>
                                                </div>

                                                <div className="price-data-1">
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data-main">
                                                                <p>
                                                                    Files
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    XLS
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    XLSX
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    CSV
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    JSON
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data-main">
                                                                <p>
                                                                    DataBase
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Oracle
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    MS SQL
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    MY SQL
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    IBM DB2
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Maria Database
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Ms Access
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    SQL Lite
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    SQL Server
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Postgre SQL
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Mongo DB
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    IBM netezza
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Teradata
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Azure SQL
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data-main">
                                                                <p>
                                                                    ERP
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    SAP ECC
                                                                </p>
                                                            </div>

                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    SAP HANA
                                                                </p>
                                                            </div>

                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Oracle ERP
                                                                </p>
                                                            </div>

                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    MS Dynamics
                                                                </p>
                                                            </div>

                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data-main">
                                                                <p>
                                                                    Online Services
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    X
                                                                </p>
                                                            </div>

                                                            <p>
                                                                Limited(1)
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Youtube
                                                                </p>
                                                            </div>

                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Facebook
                                                                </p>
                                                            </div>

                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    LinkedIn
                                                                </p>
                                                            </div>

                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="d-flex align-items-center justify-content-center">
                                                    <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Professional" target="blank">
                                                        <Button className="price-buy-now" >
                                                            Buy Now
                                                        </Button>
                                                    </Link>
                                                </div>




                                            </div>
                                        </Col>

                                        <Col lg={4} className="align-items-center justify-content-center mt-4 ">
                                            <div className="price-card-outer">
                                                <div className="price-header">
                                                    <div className="">
                                                        <h2>ENTERPRISE</h2>
                                                        <p style={{ fontSize: '0.8vw', fontWeight: '600' }}>(ON-Cloud/On-Premise)</p>
                                                    </div>

                                                </div>
                                                <div className="Price-value">
                                                    <h4 className="mt-4">
                                                        $99.99/$119.99
                                                    </h4>
                                                    <p>
                                                        Per User<br />(Year/Month)
                                                    </p>
                                                    <p className="pice-user">
                                                        10 User
                                                    </p>
                                                </div>

                                                <div className="price-data-1">
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data-main">
                                                                <p>
                                                                    Files
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    XLS
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    XLSX
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    CSV
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    JSON
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data-main">
                                                                <p>
                                                                    DataBase
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Oracle
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    MS SQL
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    MY SQL
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    IBM DB2
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Maria Database
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Ms Access
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    SQL Lite
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    SQL Server
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Postgre SQL
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Mongo DB
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    IBM netezza
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Teradata
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Azure SQL
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data-main">
                                                                <p>
                                                                    ERP
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    SAP ECC
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    SAP HANA
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Oracle ERP
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    MS Dynamics
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data-main">
                                                                <p>
                                                                    Online Services
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    X
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Youtube
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Facebook
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                        <div className="text-start sub-lis-price ">
                                                            <div className="d-flex align-itme-senter justify-content-center price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    LinkedIn
                                                                </p>
                                                            </div>

                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="d-flex align-items-center justify-content-center">
                                                    <Link to="https://smartintegraphics.com/contact-us" target="blank">
                                                        <Button className="price-buy-now" >
                                                            Contact sales
                                                        </Button>
                                                    </Link>
                                                </div>

                                            </div>
                                        </Col>

                                    </Row>

                                </Tab>

                                <Tab eventKey="data-migration" title="Data Migration">
                                    <Row>

                                        <Col lg={4} className="align-items-center justify-content-center mt-4 ">
                                            <div className="price-card-outer">
                                                <div className="price-header">
                                                    <h2>BASIC</h2>
                                                </div>
                                                <div className="Price-value">
                                                    <h4 className="mt-4">
                                                        $49.99/$65.99
                                                    </h4>
                                                    <p>
                                                        Per User<br />(Year/Month)
                                                    </p>

                                                    <p className="pice-user">
                                                        1 User
                                                    </p>

                                                </div>

                                                <div className="price-data-1">
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    SAP ECC
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    SAP HANA
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Oracle ERP
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    MS Dynamics
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p className="text-start">
                                                                    Oracle
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    MS SQL
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    MY SQL
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    IBM DB2
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Maria Database
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    MS Access
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p className="text-start">
                                                                    SQLite
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    SQL Server
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Postgre SQL
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    MonogoDB
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    IBM Netezza
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    TeraData
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Azure SQL
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex align-items-center justify-content-center">
                                                    <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Basic" target="blank">
                                                        <Button className="price-buy-now" >
                                                            Buy Now
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col lg={4} className="align-items-center justify-content-center mt-4">
                                            <div className="price-card-outer">
                                                <div className="price-header">
                                                    <h2>PROFESSIONAL</h2>
                                                </div>
                                                <div className="Price-value">
                                                    <h4 className="mt-4">
                                                        $79.99/$99.99
                                                    </h4>
                                                    <p>
                                                        Per User<br />(Year/Month)
                                                    </p>
                                                    <p className="pice-user">
                                                        3 User
                                                    </p>
                                                </div>

                                                <div className="price-data-1">
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    SAP ECC
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    SAP HANA
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Oracle ERP
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    MS Dynamics
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p className="text-start">
                                                                    Oracle
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    MS SQL
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    MY SQL
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    IBM DB2
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Maria Database
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    MS Access
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p className="text-start">
                                                                    SQLite
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    SQL Server
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Postgre SQL
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    MonogoDB
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    IBM Netezza
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    TeraData
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Azure SQL
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="d-flex align-items-center justify-content-center">
                                                    <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Professional" target="blank">
                                                        <Button className="price-buy-now" >
                                                            Buy Now
                                                        </Button>
                                                    </Link>
                                                </div>




                                            </div>
                                        </Col>

                                        <Col lg={4} className="align-items-center justify-content-center  mt-4">
                                            <div className="price-card-outer">
                                                <div className="price-header">
                                                    <div className="">
                                                        <h2>ENTERPRISE</h2>
                                                        <p style={{ fontSize: '0.8vw', fontWeight: '600' }}>(ON-Cloud/On-Premise)</p>
                                                    </div>

                                                </div>
                                                <div className="Price-value">
                                                    <h4 className="mt-4">
                                                        $99.99/$119.99
                                                    </h4>
                                                    <p>
                                                        Per User<br />(Year/Month)
                                                    </p>
                                                    <p className="pice-user">
                                                        10 User
                                                    </p>
                                                </div>

                                                <div className="price-data-1">
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    SAP ECC
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    SAP HANA
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Oracle ERP
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    MS Dynamics
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p className="text-start">
                                                                    Oracle
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    MS SQL
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    MY SQL
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    IBM DB2
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Maria Database
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    MS Access
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p className="text-start">
                                                                    SQLite
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    SQL Server
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Postgre SQL
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    MonogoDB
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    IBM Netezza
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    TeraData
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Azure SQL
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="d-flex align-items-center justify-content-center">
                                                    <Link to="https://smartintegraphics.com/contact-us" target="blank">
                                                        <Button className="price-buy-now" >
                                                            Contact sales
                                                        </Button>
                                                    </Link>
                                                </div>

                                            </div>
                                        </Col>

                                    </Row>
                                </Tab>

                                <Tab eventKey="data-analytics" title="Data Analytics" >

                                    <Row>
                                        <Col lg={4} className="align-items-center justify-content-center mt-4 ">
                                            <div className="price-card-outer">
                                                <div className="price-header">
                                                    <h2>BASIC</h2>
                                                </div>
                                                <div className="Price-value">
                                                    <h4 className="mt-4">
                                                        $49.99/$65.99
                                                    </h4>
                                                    <p>
                                                        Per User<br />(Year/Month)
                                                    </p>

                                                    <p className="pice-user">
                                                        1 User
                                                    </p>

                                                </div>

                                                <div className="price-data-1">
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Charts
                                                                </p>
                                                            </div>
                                                            <p>
                                                                16
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    DashBoards
                                                                </p>
                                                            </div>
                                                            <p>
                                                                2
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Data pre-processing
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p className="text-start">
                                                                    Auto Suggestion Charts (AI Driven)
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Download Dashboard as PDF
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Advanced Data Preparation
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Data Quering
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Refresh Rate
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Defying correlation
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Fine tune on Built-in Algorithms
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Personalized dashboards
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p className="text-start">
                                                                    Drill Down and Customize Charts
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Self-service Analytics
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    AI Generated Trends
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Vice-Activated Querying
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Smart Data Discovering
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Automated Insights
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Visualization From Insights
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Natural Language Questions
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Contexual Analysis
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Predictive Analytics
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    AI Predictive Analytics
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Mail Notification
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Mobile App
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    iMirAI[co-pilot]
                                                                </p>
                                                            </div>
                                                            <p>
                                                                <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex align-items-center justify-content-center">
                                                    <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Basic" target="blank">
                                                        <Button className="price-buy-now" >
                                                            Buy Now
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col lg={4} className="align-items-center justify-content-center mt-4 ">
                                            <div className="price-card-outer">
                                                <div className="price-header">
                                                    <h2>PROFESSIONAL</h2>
                                                </div>
                                                <div className="Price-value">
                                                    <h4 className="mt-4">
                                                        $79.99/$99.99
                                                    </h4>
                                                    <p>
                                                        Per User<br />(Year/Month)
                                                    </p>
                                                    <p className="pice-user">
                                                        3 User
                                                    </p>
                                                </div>

                                                <div className="price-data-1">
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Charts
                                                                </p>
                                                            </div>
                                                            <p>
                                                                80
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    DashBoards
                                                                </p>
                                                            </div>
                                                            <p>
                                                                10
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Refresh Rate
                                                                </p>
                                                            </div>
                                                            <p>
                                                                Hourly
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Data pre-processing
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Advanced Data Preparation
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p className="text-start">
                                                                    Auto Suggestion Charts (AI Driven)
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Data Quering
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Personalized dashboards
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    iMirAI[co-pilot]
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Download Dashboard as PDF
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                   

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Defying correlation
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Fine tune on Built-in Algorithms
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                   

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p className="text-start">
                                                                    Drill Down and Customize Charts
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Self-service Analytics
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    AI Generated Trends
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Vice-Activated Querying
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Smart Data Discovering
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Automated Insights
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Visualization From Insights
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Natural Language Questions
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Contexual Analysis
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Predictive Analytics
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    AI Predictive Analytics
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Mail Notification
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Mobile App
                                                                </p>
                                                            </div>
                                                            <p>
                                                            <ClearIcon className="cler-ico-price"/>
                                                            </p>
                                                        </div>
                                                    </div>

                                                  
                                                </div>


                                                <div className="d-flex align-items-center justify-content-center">
                                                    <Link to="https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Professional" target="blank">
                                                        <Button className="price-buy-now" >
                                                            Buy Now
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col lg={4} className="align-items-center justify-content-center mt-4 ">
                                            <div className="price-card-outer">
                                                <div className="price-header">
                                                    <div className="">
                                                        <h2>ENTERPRISE</h2>
                                                        <p style={{ fontSize: '0.8vw', fontWeight: '600' }}>(ON-Cloud/On-Premise)</p>
                                                    </div>

                                                </div>
                                                <div className="Price-value">
                                                    <h4 className="mt-4">
                                                        $99.99/$119.99
                                                    </h4>
                                                    <p>
                                                        Per User<br />(Year/Month)
                                                    </p>
                                                    <p className="pice-user">
                                                        10 User
                                                    </p>
                                                </div>

                                                <div className="price-data-1">
                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Charts
                                                                </p>
                                                            </div>
                                                            <p>
                                                                200
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    DashBoards
                                                                </p>
                                                            </div>
                                                            <p>
                                                                25
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Refresh Rate
                                                                </p>
                                                            </div>
                                                            <p>
                                                                As Nedded
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Data pre-processing
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Advanced Data Preparation
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p className="text-start">
                                                                    Auto Suggestion Charts (AI Driven)
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Data Quering
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Defying correlation
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Fine tune on Built-in Algorithms
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Personalized dashboards
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p className="text-start">
                                                                    Drill Down and Customize Charts
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Self-service Analytics
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    AI Generated Trends
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Vice-Activated Querying
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Smart Data Discovering
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Automated Insights
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Visualization From Insights
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Natural Language Questions
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Contexual Analysis
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Predictive Analytics
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    AI Predictive Analytics
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Mail Notification
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Download Dashboard as PDF
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    Mobile App
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="price-data-2">
                                                        <div className="price-data-outer">
                                                            <div className="price-inner-data">
                                                                {/* <img src={require('../assets/Header-footer/price-check.png')} className="price-check-icon" alt="check-icon" /> */}
                                                                <p>
                                                                    iMirAI[co-pilot]
                                                                </p>
                                                            </div>
                                                            <p>
                                                               <CheckIcon className="correct-ico-price" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex align-items-center justify-content-center">
                                                    <Link to="https://smartintegraphics.com/contact-us" target="blank">
                                                        <Button className="price-buy-now" >
                                                            Contact sales
                                                        </Button>
                                                    </Link>
                                                </div>

                                            </div>
                                        </Col>
                                    </Row>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>

                    <Row className="empty-ic-2">
                        <img src={require('../assets/Pricing/Img2.png')} className="price-icon-2" alt="icon-2" />
                    </Row>
                </Container>
            </section>

        </div>
    )
}